import { Col, Container, Row } from "react-bootstrap";
import ContactBlock from "../components/ContactBlock";
import BusinessHoursBlock from "../components/BusinessHoursBlock";
import Base from "../components/Base";

export default function Home() {
    return <Base>
        <img src="assets/img/WerkstattHeader2.jpg" className="w-100 object-fit-cover" style={{height: "150px", zIndex: 0}} alt="Werkstatt" />
        <Container>
            <h2 className="text-white text-border fw-bold" style={{zIndex: 1, marginTop: "-100px", marginBottom: "100px"}}>Willkommen in Ihrer Meisterwerkstatt in Aachen</h2>
            <h3>Ob Reparatur, TÜV, Lackierung oder Unfallinstandsetzung hier sind Sie an der richtigen Adresse.</h3>
            <h3>Bei uns profitieren Sie von über 20 Jahren Erfahrung.</h3>
            <div className="container-fluid pt-4">
                <Row>
                    <Col>
                        <ContactBlock />
                    </Col>
                    <Col>
                        <BusinessHoursBlock />
                    </Col>
                </Row>
            </div>
        </Container>
    </Base>
}