import Base from "../components/Base";
import Header from "../components/Header";

export default function Offers() {
    return <Base>
        <Header title="Angebote" />
        <h3>TÜV + Abgasuntersuchung für 144,00€</h3>
        <h3>Ölwechsel mit Markenöl + Ölfilter ab 49,00€</h3>
        <h3>Radwechsel ab 19,00€</h3>
        <h3>Klimaservice + Kältemittel ab 49,00€</h3>
    </Base>
}