import InfoBlock from "./InfoBlock"

export default function ContactBlock() {
    return <InfoBlock title="Kontakt" img="assets/svg/place_black_48dp.svg">
        <h5>Telefon: <a href="tel:+492414002685">+49 241 / 400 26 85</a></h5>
        <a href="mailto:kfzgering@gmail.com"><h5>kfzgering@gmail.com</h5></a>
        <br />
        <a href="http://maps.google.com/maps?q=KFZ+Gering+Grüner+Weg+28,+Aachen">
            <h5>Grüner Weg 28</h5>
            <h5>52070 Aachen</h5>
        </a>
    </InfoBlock>
}