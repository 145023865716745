import { Link, useLocation } from 'react-router-dom';
import classes from '../util/classes';

export default function NavBar() {
    const location = useLocation();
    const navClasses = "nav-link";
    const logo_width = 217.5;
    const logo_height = 107.5;
    const logo_scale = 0.7;

    return <nav className="navbar navbar-expand-sm nav-border navbar-light bg-white">
  <div className="container mx-sm-auto d-sm-flex d-sm-block flex-sm-nowrap">
  <Link to="/"><img src="assets/img/Logo.jpg" alt="Logo" width={logo_width * logo_scale} height={logo_height * logo_scale} /></Link>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-lg-0">
                <li className="nav-item">
                <Link to="/"><span className={classes(navClasses, location.pathname === "/" ? "active" : "")}>Home</span></Link> 
                </li>
                <li className="nav-item">
                    <Link to="/leistungen"><span className={classes(navClasses, location.pathname === "/leistungen" ? "active" : "")}>Leistungen</span></Link>
                </li>
                <li className="nav-item">
                    <Link to="/angebote"><span className={classes(navClasses, location.pathname === "/angebote" ? "active" : "")}>Angebote</span></Link>
                </li>
                {/*<li className="nav-item">
                    <Link to="/kontakt"><span className={classes(navClasses, location.pathname === "/kontakt" ? "active" : "")}>Kontakt</span></Link>
</li>*/}
                <li className="nav-item">
                    <Link to="/ueber-uns"><span className={classes(navClasses, location.pathname === "/ueber-uns" ? "active" : "")}>Über uns</span></Link>
                </li>
            </ul>
            </div>
    </div>
</nav>

    /*return <Navbar bg="dark" variant="dark">
        <Container>
        <Navbar.Brand className="logo-text">KFZ-Gering</Navbar.Brand>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                <Link to="/"><span className={classes(navClasses, location.pathname === "/" ? "active" : "")}>Home</span></Link> 
                </li>
                <li className="nav-item">
                    <Link to="/leistungen"><span className={classes(navClasses, location.pathname === "/leistungen" ? "active" : "")}>Leistungen</span></Link>
                </li>
                <li className="nav-item">
                    <Link to="/angebote"><span className={classes(navClasses, location.pathname === "/angebote" ? "active" : "")}>Angebote</span></Link>
                </li>
                <li className="nav-item">
                    <Link to="/kontakt"><span className={classes(navClasses, location.pathname === "/kontakt" ? "active" : "")}>Kontakt</span></Link>
                </li>
                <li className="nav-item">
                    <Link to="/ueber-uns"><span className={classes(navClasses, location.pathname === "/ueber-uns" ? "active" : "")}>Über Uns</span></Link>
                </li>
            </ul>
            </div>
        </Container>
      </Navbar>*/
}