import NavBar from "../components/NavBar";
import Footer from "./Footer";

export default function Base({children}) {
    return <>
        <NavBar />
        <div className="container px-0 bg-white flex-grow-1 pb-4">
        {children}
        </div>
        <Footer />
    </>
}