import Base from "../components/Base";

export default function Impressum() {
    return <Base>
    <div className="container text-start pt-3 ps-4">
        <h3 className="pb-2">Impressum</h3>
        <p><strong>Verantwortlichkeit / Anbieter im Sinne von § 5 TMG / § 10 Abs. 1 Nr.1 MDStV:</strong></p>
        <p>
        KFZ Gering<br/>
        Inhaber: Fedi Oral<br/>
        Grüner Weg 28<br/>
        52070 Aachen<br/>
        </p>
        <p><strong>Vertretungsberechtigter im Sinne des § 10 Abs. 1 Nr. 2 sowie Verantwortlicher nach § 10 Abs. 3 MDStV:</strong></p>
        <p>Fedi Oral</p>
        <p><strong>Ansprechperson &amp; Inhaber</strong></p>
        <p>
            Fedi Oral<br/>
        Email: <a href="mailto:kfzgering@gmail.com">kfzgering@gmail.com</a><br/>
        Internet: www.kfz-gering.de<br/>
        Telefon: 0 241 / 4002685<br/>
        Telefax: 0 241 / 4002676<br/>
        Bei Notfällen: 0176 / 550 140 00
        </p>
        <p><strong>Umsatzsteuer-Identifikationsnummer</strong><br />
        44 910 562 376</p>
        <p><strong>Preise &amp; Angebote</strong></p>
        <p>Alle Preise in dieser Internetpräsents verstehen sich inklusive der gesetzlichen Mehrwertsteuer. Irrtümer in Beschreibung, Preis und Ähnliches vorbehalten. Es besteht kein Anspruch auf ein Angebot. Alle Angaben sind ohne Gewähr.</p>
        <p><strong>Rechtliche Hinweise</strong></p>
        <p>Alle Bilder, Texte und Designelemente dieser Internetpräsenz sind Eigentum von KFZ Gering und dürfen nur mit schriftlicher Genehmigung verwendet werden.</p>
        <p>Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.</p>
        <p>Alle Angaben und Angebote dieser Website gelten unter Vorbehalt und stehen unter Ausschluss jeglicher Gewährleistung zur Verfügung. Für die Richtigkeit, Vollständigkeit, Irrtümer, Schreibfehler und Aktualität übernimmt der Betreiber keine Gewähr.</p>
    </div>
    </Base>
}