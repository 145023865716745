import Base from "../components/Base";

export default function Privacy() {
    return <Base>
    <div className="text-start container p-4">
        <h1>Datenschutzerklärung</h1>
        <p>Vielen Dank für Ihren Besuch auf unserer Website. Der Schutz Ihrer personenbezogenen Daten liegt uns sehr am Herzen. Im Folgenden möchten wir Sie darüber informieren, welche Daten während Ihres Besuchs auf unserer Website erfasst und wie diese genutzt werden.</p>
    
    <h2>1. Verantwortliche Stelle</h2>
    <p>Die verantwortliche Stelle für die Verarbeitung Ihrer personenbezogenen Daten im Sinne der Datenschutzgesetze ist:</p>
    <p>KFZ Gering<br/>
    Vertretungsberechtigte Personen: Fedi Oral<br/>
    Grüner Weg 28 <br/>
    52070 Aachen<br/>
    kfzgering@gmail.com</p>
    
    <h2>2. Erfasste Daten</h2>
    <p>Bei jedem Zugriff auf unsere Website werden automatisch bestimmte Informationen in Logfiles gespeichert. Diese Daten können Rückschlüsse auf Ihre Person zulassen, sind jedoch nicht dazu bestimmt, Sie persönlich zu identifizieren. Die folgenden Daten werden erfasst:</p>
    <ul>
        <li>Referenz-URL (die zuvor besuchte Seite)</li>
        <li>Datum und Uhrzeit des Zugriffs</li>
        <li>Übertragene Datenmenge</li>
        <li>Aufgerufene Seiten und Dateien</li>
        <li>Quelle/Verweis, von welchem Sie auf die Seite gelangten</li>
        <li>Art des verwendeten Browsers</li>
        <li>Betriebssystem des zugreifenden Geräts</li>
        <li>IP-Adresse des zugreifenden Geräts</li>
    </ul>
    
    <h2>3. Zweck der Datenerfassung</h2>
    <p>Die Erfassung der Logfiles dient ausschließlich zur Gewährleistung der Sicherheit und Stabilität unserer Website. Die erhobenen Daten werden für folgende Zwecke verwendet:</p>
    <ul>
        <li>Erkennung und Abwehr von Angriffen auf unsere IT-Infrastruktur</li>
        <li>Fehleranalyse und Fehlerbehebung</li>
        <li>Statistische Auswertung</li>
        <li>Verbesserung der Benutzerfreundlichkeit unserer Website</li>
        <li>Gewährleistung der Einhaltung rechtlicher Anforderungen</li>
    </ul>
    
    <h2>4. Rechtsgrundlage für die Datenverarbeitung</h2>
    <p>Die Verarbeitung Ihrer personenbezogenen Daten basiert auf unserem berechtigten Interesse gemäß Artikel 6 Absatz 1 lit. f der Europäischen Datenschutz-Grundverordnung (DSGVO). Unser berechtigtes Interesse liegt in den oben genannten Zwecken der Datenerfassung.</p>
    
    <h2>5. Dauer der Datenspeicherung</h2>
    <p>Die in den Logfiles erfassten Daten werden für einen begrenzten Zeitraum gespeichert. Die genaue Speicherdauer hängt von den technischen Erfordernissen und der Sicherheit unserer Systeme ab. In der Regel werden die Daten nach 7 Tagen automatisch gelöscht.</p>
    
    <h2>6. Weitergabe der Daten</h2>
    <p>Eine Weitergabe Ihrer personenbezogenen Daten an Dritte findet grundsätzlich nicht statt, es sei denn, es besteht eine rechtliche Verpflichtung zur Weitergabe. Eine Übermittlung in Drittländer außerhalb der Europäischen Union findet ebenfalls nicht statt.</p>
    
    <h2>7. Ihre Rechte</h2>
    <p>Sie haben das Recht, Auskunft über die von uns über Sie gespeicherten personenbezogenen Daten zu erhalten. Darüber hinaus haben Sie das Recht auf Berichtigung, Löschung und Einschränkung der Verarbeitung Ihrer Daten. Außerdem steht Ihnen ein Widerspruchsrecht gegen die Verarbeitung zu.</p>
    
    <h2>8. Kontakt</h2>
    <p>Für Anfragen oder weitere Informationen zur Verarbeitung Ihrer personenbezogenen Daten sowie zur Ausübung Ihrer Rechte können Sie sich jederzeit an uns wenden:</p>
    <p>KFZ Gering<br/>
    Vertretungsberechtigte Personen: Fedi Oral<br/>
    Grüner Weg 28 <br/>
    52070 Aachen<br/>
    kfzgering@gmail.com</p>
    
    <p>Bitte beachten Sie, dass Datenschutzbestimmungen und -praktiken sich ändern können. Es empfiehlt sich daher, diese Datenschutzerklärung regelmäßig zu überprüfen und auf dem neuesten Stand zu bleiben.</p>
    
    <p>Stand: 14.06.2023</p>
    </div>
    </Base>
}