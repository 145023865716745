import './App.css';
import {Routes, Route} from 'react-router-dom';
import Home from './views/Home';
import Offers from './views/Offers';
import Service from './views/Service';
import About from './views/About';
import Contact from './views/Contact';
import Impressum from './views/Impressum';
import Privacy from './views/Privacy';

function App() {
  return (
    <div className="App d-flex justify-content-center min-vh-100 p-0">
      <div className='position-relative w-100 d-flex flex-column'>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='angebote' element={<Offers />} />
          <Route path='leistungen' element={<Service />} />
          <Route path='kontakt' element={<Contact />} />
          <Route path='ueber-uns' element={<About />} />
          <Route path='impressum' element={<Impressum />} />
          <Route path='datenschutz' element={<Privacy />} />
        </Routes>
        </div>
    </div>
  );
}

export default App;
