import { Col, Row } from "react-bootstrap";
import classes from "../util/classes";

export default function PictureBlock({img, imgClass, imgOnRight, desc, header, children}) {
    const Image = <img className={classes(imgClass, "w-100")} src={img} alt={"Image-" + desc} style={{minWidth: "400px", maxHeight: "400px"}}/>;
    const Text = <div className="w-100"><h3>{header}</h3>{children}</div>;

    return <div className="pt-4 pb-4">
        <Row>
            <Col className="mt-auto mb-auto">
                {imgOnRight ? Text : Image}
            </Col>
            <Col className="mt-auto mb-auto">
                {imgOnRight ? Image : Text}
            </Col>
        </Row>
    </div>
}