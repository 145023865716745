import Base from "../components/Base";
import Header from "../components/Header";
import PictureBlock from "../components/PictureBlock";

export default function About() {
    return <Base>
        <Header title="Über uns" />
    <div className="mx-4">
        {/*<Row>
            <Col style={{minWidth: "260px"}} className="mt-4">
                <h6>KFZ-Gering ist ein Familienunternehmen, das bereits im Jahre 2000 von Friedel Gering gegründet wurde.</h6>
                <h6>Wir beschäftigen derzeit einen KFZ-Meister und zwei weitere Mitarbeiter.</h6>
                <h6>Kunden- und Serviceorientierung stehen im Mittelpunkt unseres Handelns.</h6>
                <h6>Als Kunde bei uns profitieren Sie von über 20 Jahren Know-how in der KFZ-Branche.</h6>
            </Col>
            <Col>
            <img src="assets/img/Gering.jpg" className="w-100 object-fit-cover h-50" style={{minWidth: "260px", maxHeight: "600px"}} alt="Gering" />
            </Col>
        </Row>*/}
        <PictureBlock imgOnRight={true} title={""} img="assets/img/Gering.jpg" imgClass="w-100 object-fit-cover" desc="Gering">
            <h6>KFZ-Gering ist ein Familienunternehmen, das bereits im Jahre 2000 von Friedel Gering gegründet wurde.</h6>
            <h6>Wir beschäftigen derzeit einen KFZ-Meister und zwei weitere Mitarbeiter.</h6>
            <h6>Kunden- und Serviceorientierung stehen im Mittelpunkt unseres Handelns.</h6>
            <h6>Als Kunde bei uns profitieren Sie von über 20 Jahren Know-how in der KFZ-Branche.</h6>
        </PictureBlock>
        <PictureBlock imgOnRight={false} title={""} img="assets/img/Werkstatt-Innen.jpg" imgClass="w-100 object-fit-cover" desc="WerkstattInnen">
            Unsere Werkstatt bietet jegliche Leistungen rund um Ihr Auto, von einfacher Reparatur bis hin zur Unfallinstandsetzung. Außerdem bieten wir eine tägliche HU an.
        </PictureBlock>
    </div>
    </Base>
}