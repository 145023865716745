import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function Footer() {
    return <div className="container Footer p-1">
        <div className="container">
            <Row>
                <Col className="col-auto me-auto">
                    © 2023 KFZ-Gering
                </Col>
                <Col className="col-auto"><Link to="/impressum"><div className="button text-white">Impressum</div></Link></Col>
                <Col className="col-auto"><Link to="/datenschutz"><div className="button text-white">Datenschutz</div></Link></Col>
            </Row>
        </div>
    </div>
}