import Base from "../components/Base";
import Header from "../components/Header";
import PictureBlock from "../components/PictureBlock";

export default function Service() {
    return <Base>
        <Header title="Leistungen" />
        <div className="container">
        <PictureBlock imgOnRight={false} img="assets/img/Reparatur.jpg" desc="Reparatur" header="Reparaturen jeglicher Art">
            <h5>Wir führen Wartungen und Reparaturen nach Herstellervorgaben an allen gängigen Fahrzeugtypen durch.</h5>
        </PictureBlock>
        <PictureBlock imgOnRight={true} img="assets/img/Lackierung.jpg" desc="Lackierung" header="Lackierungen">
            <h5>Ob Teil oder auch ganze Lackierungen. Wir sind der richtige Ansprechpartner.</h5>
        </PictureBlock>
        <PictureBlock imgOnRight={false} img="assets/img/Tuev.jpg" desc="Tuev" header="TÜV - Hauptuntersuchung">
            <h5>Wir bieten eine tägliche HU an.</h5>
        </PictureBlock>
        <PictureBlock imgOnRight={true} img="assets/img/Exhaust.jpg" desc="Abgas" header="AU - Abgasuntersuchung">
            <h5>Für Diesel - und Benzinmotoren aller Hersteller.</h5>
        </PictureBlock>
        <PictureBlock imgOnRight={false} img="assets/img/OilCheck.jpg" desc="Inspektion" header="Inspektionsservice">
            <h5>Wir führen die Inspektion aller Hersteller durch.</h5>
        </PictureBlock>
        <PictureBlock imgOnRight={true} img="assets/img/OilChange.jpg" desc="Ölwechsel" header="Ölwechselservice">
            <h5>Wir verwenden hierfür immer ein Markenöl.</h5>
        </PictureBlock>
        <PictureBlock imgOnRight={false} img="assets/img/Transmission.jpg" desc="M+G-Instandsetzung" header="Motor- und Getriebeinstandsetzung">
            <h5>Nach Prüfung und in Absprache mit Ihnen überholen wir alle Motoren und Getriebe.</h5>
        </PictureBlock>
        <PictureBlock imgOnRight={true} img="assets/img/Tyre.jpg" desc="Achsenvermessung" header="Achsenvermessung">
            <h5>Auf Wunsch führen wir eine  Überprüfung und Vermessung Ihres Fahrwerks durch.</h5>
        </PictureBlock>
        <PictureBlock imgOnRight={false} img="assets/img/Electronic.jpg" desc="Fahrzeugelektronik" header="Fahrzeugelektronik">
            <h5>Das auslesen der Fahrzeugelektronik bieten wir Ihnen gerne an um Fehler schnell und sicher zu finden und zu beseitigen.</h5>
        </PictureBlock>
        <PictureBlock imgOnRight={true} img="assets/img/Tyre.jpg" desc="Reifenservice" header="Reifenservice und Einlagerung">
            <h5>Wir prüfen, montieren Reifen aller namhaften Hersteller. Außerdem bieten wir Ihnen das Einlagern Ihrer Reifen an.</h5>
        </PictureBlock>
        <PictureBlock imgOnRight={false} img="assets/img/Crash.jpg" desc="Unfall" header="Unfall- und Karosserieinstandsetzung">
            <h5>Fachgerechte Unfallinstandsetzung aller größeren und auch kleineren Schäden führen wir durch.</h5>
        </PictureBlock>
        <PictureBlock imgOnRight={true} img="assets/img/Brakes.jpg" desc="Bremsen" header="Bremsenservice">
            <h5>Fachgerechte Unfallinstandsetzung aller größeren und auch kleineren Schäden führen wir durch.</h5>
        </PictureBlock>
        <PictureBlock imgOnRight={false} img="assets/img/Rental.jpg" desc="Leihwagen" header="Leihwagenservice">
            <h5>Wir können Ihnen auch einen Leihwagen anbieten.</h5>
        </PictureBlock>
        </div>
    </Base>
}